import React, { useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { setMetalness } from './util';
import { useGLTF } from '@react-three/drei';

export const HomepageShip = ({ speed, model, ...props }) => {
  const group = useRef();
  
  const { nodes, materials } = useGLTF( '/gltf/ship1.gltf')
  setMetalness(materials, 0.15);
  const factor = 0.1;

  useFrame((state, delta) => {
    group.current.rotation.y -= Math.sin((delta * factor) / 2) * Math.cos((delta * factor) / 2) * 1.5
  })

  const material = materials.PolygonPirates_Mat_01_A;

  return (
    <group ref={group} rotation-y={Math.PI} {...props} dispose={null}>
      <group
        position={[100, -1.8, 0]}
        rotation={[-Math.PI / 32, 0, 0]}
      >
        <mesh
          material={material}
          geometry={nodes.SM_Veh_Boat_Medium_01_Hull_293.geometry}
          position={[-0.06, -1.35, -0.34]}
          scale={[0.01, 0.01, 0.01]}>
          <mesh
            material={material}
            geometry={nodes.SM_Prop_Cannon_311.geometry}
            position={[0, 245.86, 475.24]}
            rotation={[-0.06, 0, 0]}
            scale={[1.09, 1.09, 1.09]}
          />
          <mesh
            material={material}
            geometry={nodes.SM_Prop_ShipWheel_01_308.geometry}
            position={[0, 450.74, -457.55]}
          />
          <mesh
            material={material}
            geometry={nodes.SM_Veh_Boat_Medium_01_Mast_296.geometry}
            position={[0, 208.17, 41.62]}>
            <mesh
              material={material}
              geometry={nodes.SM_Veh_Boat_Medium_01_Sails_299.geometry}
              position={[0, 0, 0]}
            />
          </mesh>
        </mesh>
      </group>
    </group>
  )
}
