import React from 'react';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error) {
    return {};
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
  }

  render() {
    return this.props.children;
  }
}
