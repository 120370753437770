import * as THREE from "three"
import React, { useMemo } from "react"
import { useFrame, useLoader } from "@react-three/fiber"

const useWaves = (arg) => {
  return useMemo(() => {
    return `
    #define SCALE 20.0

    varying vec2 vUv;
    uniform float uTime;
    #include <fog_pars_vertex>

    float calculateSurface(float x, float z) {
        float y = 0.0;
        y += (sin(x * 1.0 / SCALE + uTime * 1.0) + sin(x * 2.3 / SCALE + uTime * 1.5) + sin(x * 3.3 / SCALE + uTime * 0.4)) / 3.0;
        y += (sin(z * 0.2 / SCALE + uTime * 1.8) + sin(z * 1.8 / SCALE + uTime * 1.8) + sin(z * 2.8 / SCALE + uTime * 0.8)) / 3.0;
        return y;
    }

    void main() {
        #include <begin_vertex>
        #include <project_vertex>
        #include <fog_vertex>

        vUv = uv;
        vec3 pos = position;

        float strength = ${arg};
        pos.z += strength * calculateSurface(pos.x, pos.y);

        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
    }
    `
  }, [arg])
}

const fragmentShader = `
varying vec2 vUv;

uniform sampler2D uMap;
uniform float uTime;
uniform vec3 uColor;
#include <fog_pars_fragment>

void main() {
    vec2 uv = vUv * 10.0 + vec2(uTime * -0.05);

    uv.y += 0.01 * (sin(uv.x * 3.5 + uTime * 0.35) + sin(uv.x * 4.8 + uTime * 1.05) + sin(uv.x * 7.3 + uTime * 0.45)) / 3.0;
    uv.x += 0.12 * (sin(uv.y * 4.0 + uTime * 0.5) + sin(uv.y * 6.8 + uTime * 0.75) + sin(uv.y * 11.3 + uTime * 0.2)) / 3.0;
    uv.y += 0.12 * (sin(uv.x * 4.2 + uTime * 0.64) + sin(uv.x * 6.3 + uTime * 1.65) + sin(uv.x * 8.2 + uTime * 0.45)) / 3.0;

    vec4 tex1 = texture2D(uMap, uv * 1.0);
    vec4 tex2 = texture2D(uMap, uv * 1.0 + vec2(0.2));

    vec3 color = uColor;

    gl_FragColor = vec4(color + vec3(tex1.a * 0.9 - tex2.a * 0.02), 1.0);
    #include <fog_fragment>
}
`


export function Ocean(props) {
  const waves = useWaves(props.waves || '1.25');

  const color = props.color || "#0051da";

  const uniforms = THREE.UniformsUtils.merge([
    THREE.UniformsLib['fog'],
    {
      uMap: { type: "t", value: null },
      uTime: { type: "f", value: 0 },
      uColor: { type: "f", value: new THREE.Color(color) },
    }
  ])
  const texture = useLoader(THREE.TextureLoader, "/textures/water.png")

  useFrame(() => {
    uniforms.uTime.value = window.performance.now() / 1700;
  });

  const shader = useMemo(() => {
    let shade = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: waves,
      fragmentShader: fragmentShader,
      side: THREE.DoubleSide,
      fog: true
    })
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping
    shade.uniforms.uMap.value = texture
    return shade
  }, [texture, uniforms, waves])

  return (
    <mesh {...props} material={shader}>
    </mesh>
  )
}
