import React, { useRef, useMemo, } from 'react'
import {Color, MeshStandardMaterial} from 'three';
import { useGLTF } from '@react-three/drei';

export function SkullRock(props) {
  const ref = useRef();
  const { nodes } = useGLTF('/gltf/skullrock.gltf');
  const normalMaterial = useMemo(() => new MeshStandardMaterial(
    { color: new Color(0x606054)  },
    ), []);

  return (
    <group
      ref={ref}
      {...props}
      dispose={null}
    >
      <mesh
        material={normalMaterial}
        geometry={nodes.SM_Env_Rock_Skull_01_880.geometry}
        scale={[0.01, 0.01, 0.01]}
      />
    </group>
  )
}
