import React, { Suspense } from "react"
import { Canvas, } from "@react-three/fiber"
import { OrbitControls, Stars, Sky, Html } from "@react-three/drei"
import { Ocean, Island1, SkullRock, HomepageShip, } from "./components"
import './App.css';
import { Vector3 } from 'three';
import { ACESFilmicToneMapping, sRGBEncoding } from 'three'

//const storm = "distance(pos, vec3(0, 0, 0))/25.0";

const DaytimeComponents = ({ night }) => {
  if (night) {
    return (<>
      <fogExp2 attach="fog" args={[0x010101, 0.0035]} />
      <Stars backgroundColor={0x010101} />
    </>)
  }

  return (<>
    <fog attach="fog" args={[0x9a6921, 0.0065]} />
    <Sky azimuth={0.5} sunPosition={new Vector3(0, .5, 0)} />
  </>)
}


const Index = () => {
  // const webGL2 = useMemo(() => {
  //   if (typeof window === 'undefined' || isBrowser) {
  //     return false
  //   }
  //   return !!window.document.createElement('canvas').getContext('webgl2');
  // }, [isBrowser]);

  const loading = (
    <Html>
      <div className="loading">
        <h1>Loading...</h1>
      </div>
    </Html>
  )

  return (
    <>
      <noscript>
        This site requires javascript.
      </noscript>
      <Canvas style={{ backgroundColor: 'black' }} camera={{ position: [0, 8, 75] }}
        gl={{
          antialias: true,
          toneMapping: ACESFilmicToneMapping,
          outputEncoding: sRGBEncoding,
        }}
      >
        <DaytimeComponents night={true} />

        <ambientLight intensity={.1}/>
        <directionalLight
          position={[-2, 2, 3]}
          intensity={1}
          castShadow
          shadow-mapSize={[1024 * 2, 1024 * 2]}
        />

        <Suspense fallback={loading}>
          <Ocean
            color={0x2855c7}
            scale={[0.75, 0.75, 0.75]}
            position={[0, -0.5, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            waves={'3.0'}
          >
            <planeGeometry attach="geometry" args={[1000, 1000, 150, 150]} />
          </Ocean>
          <HomepageShip position={[-25, 0, -25]} />
          <Island1 rotation={[0, 2 * Math.PI / 3, 0]} />
          <SkullRock
            position={[0, 1, 2.5]}
          />
        </Suspense>

        <OrbitControls
          enableKeys={false}
          enablePan={false}
          maxPolarAngle={Math.PI / 2.25}
          minDistance={30}
          maxDistance={100}
        />
      </Canvas>
    </>
  )
}
export default Index
