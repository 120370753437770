import React, { useRef } from 'react'
import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import {setMetalness} from './util';

export function Island1(props) {
  const group = useRef()
  const { nodes, materials } = useLoader(GLTFLoader, '/gltf/island1.glb')
  setMetalness(materials, 0.38);
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Beach_69.geometry}
        position={[-60.87, -5.5, 102.37]}
        rotation={[0, -1.15, 0]}
        scale={[0.02, 0.02, 0.02]}>
        <mesh
          material={materials.PolygonPirates_Mat_01_A}
          geometry={nodes.SM_Env_Beach_11.geometry}
          position={[-8.4, -417.18, 25.2]}
          rotation={[0, 0.54, 0]}
          scale={[1.51, 3.87, 1.61]}
        />
        <mesh
          material={materials.PolygonPirates_Mat_01_A}
          geometry={nodes.SM_Env_PalmTree_2.geometry}
          position={[4.2, 317.55, 8.4]}
          scale={[0.48, 0.72, 0.48]}
        />
        <mesh
          material={materials.PolygonPirates_Mat_01_A}
          geometry={nodes.SM_Env_PalmTree_3.geometry}
          position={[-62.99, 317.55, -37.8]}
          rotation={[-Math.PI, 0.65, -Math.PI]}
          scale={[0.42, 0.62, 0.42]}
        />
        <mesh
          material={materials.PolygonPirates_Mat_01_A}
          geometry={nodes.SM_Env_PalmTree_4.geometry}
          position={[-67.19, 305.1, 100.79]}
          rotation={[Math.PI, -1.38, Math.PI]}
          scale={[0.42, 0.62, 0.42]}
        />
      </mesh>
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Beach_54.geometry}
        position={[24.57, -0.05, 15.2]}
        rotation={[0, -0.87, 0]}
        scale={[0.02, 0, 0.02]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Beach_48.geometry}
        position={[-4.37, -14.24, -12.23]}
        rotation={[-Math.PI, 0.96, -Math.PI]}
        scale={[0.06, 0.09, 0.04]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Beach_49.geometry}
        position={[40.53, -14.24, -30.63]}
        rotation={[Math.PI, -0.24, Math.PI]}
        scale={[0.06, 0.09, 0.04]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Beach_119.geometry}
        position={[89.43, -14.24, -33.23]}
        rotation={[0, 0.32, 0]}
        scale={[0.06, 0.09, 0.04]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Beach_51.geometry}
        position={[53.53, -14.24, 25.27]}
        rotation={[-Math.PI, 0.67, -Math.PI]}
        scale={[0.06, 0.09, 0.04]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Beach_52.geometry}
        position={[12.83, -14.24, 30.57]}
        rotation={[-Math.PI, 0.62, -Math.PI]}
        scale={[0.06, 0.09, 0.04]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Beach_53.geometry}
        position={[60.13, -14.24, -5.73]}
        rotation={[0, -0.5, 0]}
        scale={[0.06, 0.09, 0.04]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Beach_22.geometry}
        position={[26.08, -3.49, 15.94]}
        rotation={[0, -0.17, 0]}
        scale={[0.02, 0.03, 0.02]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Beach_2.geometry}
        position={[79.21, -3.71, -30.71]}
        rotation={[0, 0.17, 0]}
        scale={[0.03, 0.02, 0.03]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Beach_13.geometry}
        position={[0.93, -3.88, -3.81]}
        rotation={[-Math.PI, Math.PI / 3, -Math.PI]}
        scale={[0.03, 0.02, 0.03]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Beach_03_6.geometry}
        position={[51.9, -3.57, 24.23]}
        rotation={[0, Math.PI / 9, 0]}
        scale={[0.02, 0.01, 0.02]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Huge_884.geometry}
        position={[14.07, -3.77, 29.75]}
        rotation={[0, 0.45, 0]}
        scale={[0, 0, 0]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Huge_886.geometry}
        position={[-19.51, -9.77, 34.5]}
        rotation={[0, -0.55, 0]}
        scale={[0, 0.01, 0]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Huge_910.geometry}
        position={[56.44, -5.49, 8.25]}
        rotation={[-Math.PI, 0.06, -Math.PI]}
        scale={[0, 0, 0]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Huge_921.geometry}
        position={[10.75, -7.29, 5.73]}
        rotation={[Math.PI, -1.32, Math.PI]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Huge_68.geometry}
        position={[-2.11, -5.69, 12.23]}
        rotation={[0, 0.33, 0]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Huge_69.geometry}
        position={[19.69, -5.49, -3.58]}
        rotation={[-Math.PI, 0.08, -Math.PI]}
        scale={[0, 0, 0]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Huge_942.geometry}
        position={[40.57, -2.76, 10.05]}
        rotation={[Math.PI, -0.95, Math.PI]}
        scale={[0, 0, 0]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Huge_02_889.geometry}
        position={[40.48, -4.32, 50.7]}
        scale={[0, 0, 0]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Huge_896.geometry}
        position={[42.46, -4.32, 14.34]}
        rotation={[0, -0.01, 0]}
        scale={[0, 0, 0]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Large_04_894.geometry}
        position={[40.23, -3.08, 34.02]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Large_010_899.geometry}
        position={[50.39, -3.24, 12.26]}
        rotation={[-1.45, 0.03, 0.27]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Large_919.geometry}
        position={[11.77, -6.3, 28.59]}
        rotation={[0, 1.48, 0]}
        scale={[0.02, 0.04, 0.02]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Large_920.geometry}
        position={[8.96, -4.04, 19.2]}
        rotation={[0, -1.29, 0]}
        scale={[0.02, 0.04, 0.03]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Large_923.geometry}
        position={[8.22, 1.51, 6.54]}
        rotation={[-Math.PI, 0.3, -Math.PI]}
        scale={[0.02, 0.03, 0.02]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Large_48.geometry}
        position={[10.08, 0.05, 32.32]}
        rotation={[0, 0.33, 0]}
        scale={[0.02, 0.02, 0.02]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Large_924.geometry}
        position={[69.65, -4.23, -27.73]}
        rotation={[Math.PI, -0.58, Math.PI]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Large_925.geometry}
        position={[63.96, -4.2, -49.45]}
        rotation={[-Math.PI, 1.41, -Math.PI]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Large_927.geometry}
        position={[20.06, -4.2, -19.07]}
        rotation={[Math.PI, -1.48, Math.PI]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Large_929.geometry}
        position={[49.04, -4.2, 27.02]}
        rotation={[0, 0.95, 0]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Large_936.geometry}
        position={[-34.21, -6.6, -21.36]}
        rotation={[0, 0.32, 0]}
        scale={[0.01, 0.02, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Large_50.geometry}
        position={[-3.89, -6.6, -33.54]}
        rotation={[Math.PI, -1.3, Math.PI]}
        scale={[0.01, 0.02, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Large_963.geometry}
        position={[72.98, -4.8, 3.78]}
        rotation={[0, 1.4, 0]}
        scale={[0.01, 0.02, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Arch_932.geometry}
        position={[6.53, -4.29, 34.06]}
        rotation={[0, 0.4, 0]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_Tall_945.geometry}
        position={[-15.22, -0.62, -33.31]}
        rotation={[-Math.PI, 1.05, -Math.PI]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_Tall_948.geometry}
        position={[33.84, -0.01, 28.3]}
        rotation={[0, -0.49, 0]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_Tall_8.geometry}
        position={[18.74, -0.01, 18.83]}
        rotation={[Math.PI, -1.04, Math.PI]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_Tall_952.geometry}
        position={[24.29, 1.24, 19.3]}
        rotation={[0, -1.28, 0]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_Tall_271.geometry}
        position={[64.45, 0.6, -20.69]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_265.geometry}
        position={[62.87, -0.2, -31.06]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_274.geometry}
        position={[69.37, 0.59, -21.47]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_289.geometry}
        position={[23.09, -0.21, 30.52]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_295.geometry}
        position={[28.34, 0.83, 15.28]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_298.geometry}
        position={[16.39, -0.18, 21.56]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_307.geometry}
        position={[22.44, 1.17, 16.54]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_268.geometry}
        position={[59.65, 0.3, -20.79]}
        rotation={[Math.PI, -0.32, Math.PI]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_313.geometry}
        position={[6.38, -0.82, -9.56]}
        rotation={[-Math.PI, 1.14, -Math.PI]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_277.geometry}
        position={[71.62, 0.69, -18.67]}
        rotation={[0, -0.69, 0]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_283.geometry}
        position={[69.46, -1.35, 12.73]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_286.geometry}
        position={[33.41, -0.61, 31.4]}
        rotation={[Math.PI, -1.51, Math.PI]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_292.geometry}
        position={[29.79, 0.36, 15.55]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_301.geometry}
        position={[21.79, 0.54, 19.63]}
        rotation={[Math.PI, -1.47, Math.PI]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_304.geometry}
        position={[20.94, 1.48, 14.7]}
        rotation={[0, 1.5, 0]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_310.geometry}
        position={[5.26, -0.98, -8.41]}
        rotation={[-Math.PI, 1.24, -Math.PI]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_316.geometry}
        position={[32.58, -0.16, 8.92]}
        rotation={[-Math.PI, 0.99, -Math.PI]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_319.geometry}
        position={[19.91, 0.11, 4.94]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_PalmTree_280.geometry}
        position={[72.21, 0.48, -20.46]}
        rotation={[0, 0.6, 0]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Large_452.geometry}
        position={[55.6, -3.84, -19.15]}
        rotation={[0, 0.23, 0]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Large_71.geometry}
        position={[51.05, -3.84, 1.02]}
        rotation={[Math.PI, -1.14, Math.PI]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Large_72.geometry}
        position={[48.84, -3.84, 16.3]}
        rotation={[-Math.PI, 0.5, -Math.PI]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Large_73.geometry}
        position={[15.37, -3.84, -9.58]}
        rotation={[Math.PI, -1.14, Math.PI]}
        scale={[0.01, 0.01, 0.01]}
      />
      <mesh
        material={materials.PolygonPirates_Mat_01_A}
        geometry={nodes.SM_Env_Rock_Large_74.geometry}
        position={[13.48, -1.37, -1.92]}
        rotation={[Math.PI, -0.67, 0]}
        scale={[-0.01, -0.01, -0.01]}
      />
    </group>
  )
}
